<template>
  <div class="van-main order-detail">
    <van-tabs v-model="active">
      <van-tab title="订单信息">
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="项目名称" :value="utils.isEffectiveCommon(orderDetail.projectName)" />
            <van-cell title="项目编号" :value="utils.isEffectiveCommon(orderDetail.projectNo)" />
            <van-cell title="产品类型" :value="utils.statusFormat(String(orderDetail.productType), 'ProjectProductType')" />
            <van-cell title="货品分类" :value="utils.isEffectiveCommon(orderDetail.firstCategoryName)" />
            <van-cell title="订单名称" :value="utils.isEffectiveCommon(orderDetail.orderName)" />
            <van-cell title="订单编号" :value="utils.isEffectiveCommon(orderDetail.orderNo)" />
          </van-cell-group>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="统一社会信用代码" :value="utils.isEffectiveCommon(orderDetail.dsEnterpriseOrgNo)" />
            <van-cell title="供应商编号" :value="utils.isEffectiveCommon(orderDetail.usEnterpriseOrgNo)" />
            <van-cell title="委托客户" :value="utils.isEffectiveCommon(orderDetail.dsEnterpriseName)" />
            <van-cell title="供应商" :value="utils.isEffectiveCommon(orderDetail.usEnterpriseName)" />
            <!--            <van-cell title="厂号" :value="utils.isEffectiveCommon(orderDetail.factoryNumber)" />-->
            <van-cell title="贸易执行企业" :value="utils.isEffectiveCommon(orderDetail.tradeOrgName)" />
          </van-cell-group>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="资金方" :value="utils.isEffectiveCommon(orderDetail.tenantName)" />
            <van-cell title="项目创建人" :value="utils.isEffectiveCommon(orderDetail.createUserName)" />
            <van-cell title="关联主合同" :value="utils.isEffectiveCommon(orderDetail.contractName)" />
            <van-cell title="关联主合同编号" :value="utils.isEffectiveCommon(orderDetail.contractNumber)" />
          </van-cell-group>
        </div>
        <div class="area-title">
          <p class="title">
            物流信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="物流方式" :value="utils.statusFormat(Number(orderDetail.logisticsMode),'LogisticsMode')" />
            <van-cell title="起运地" :value="utils.isEffectiveCommon(orderDetail.originPlace)" />
            <van-cell title="装运口岸" :value="utils.isEffectiveCommon(orderDetail.shipmentPort)" />
            <van-cell title="目的地" :value="utils.isEffectiveCommon(orderDetail.destination)" />
            <van-cell title="目的口岸" :value="utils.isEffectiveCommon(orderDetail.destinationPort)" />
            <van-cell title="申报口岸" :value="utils.isEffectiveCommon(orderDetail.entryPort)" />
          </van-cell-group>
        </div>
        <div class="area-title">
          <p class="title">
            结算信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <!-- <van-cell title="价格术语" :value="utils.statusFormat(orderDetail.priceTerms,'PriceTerms')" />
            <van-cell title="付款条款" :value="utils.statusFormat(orderDetail.paymentProvision,'PaymentProvision')" /> -->
            <van-cell title="结算方式" :value="utils.isEffectiveCommon(orderDetail.statementConfirmForm)" />
            <van-cell title="付款条款" :value="utils.statusFormat(orderDetail.paymentProvision,'PaymentProvision')" />
          </van-cell-group>
        </div>
        <div class="area-title">
          <p class="title">
            交(提)货信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="结算周期（天）" :value="utils.isEffectiveCommon(fundsForm.settlementCycle)" />
            <van-cell title="货物质量" :value="utils.isEffectiveCommon(orderDetail.billConfrimForm)" />
            <!-- <van-cell title="结算方式" :value="utils.isEffectiveCommon(orderDetail.statementConfirmForm)" /> -->
            <!-- <van-cell title="备注" :value="utils.isEffectiveCommon(orderDetail.remarks)" /> -->
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="客户信息">
        <div class="area-title">
          <p class="title">
            客户存量业务情况
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="柜数合计" :value="utils.isEffectiveCommon(orderDetail.cabinetsNumber)" />
            <van-cell title="合同数量合计（吨）" :value="utils.weightFormat(orderDetail.totalContractQuantity)" />
            <van-cell title="已支付总金额（万美元）" :value="utils.moneyFormat(orderDetail.paidtTotalContractAmount)" />
            <van-cell title="销售回款（万元）" :value="utils.moneyFormat(orderDetail.salesCollection)" />
            <van-cell title="剩余可用额度(万元)" :value="utils.moneyFormat(orderDetail.availableLimit)" />
            <van-cell title="合同总金额（万美元）" :value="utils.moneyFormat(orderDetail.totalContractAmountUs)" />
            <van-cell title="合同总金额（万元）" :value="utils.moneyFormat(orderDetail.totalContractAmount)" />
            <van-cell title="实际占用资金（万元）" :value="utils.moneyFormat(orderDetail.actualOccupiedFunds)" />
            <van-cell title="关联主合同" :value="utils.isEffectiveCommon(orderDetail.contractNumber)" />
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="供应商信息">
        <div class="area-title">
          <p class="title">
            海外供应商基本信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="海外供应商英文名称" :value="utils.isEffectiveCommon(supplierForm.englishName)" />
            <van-cell title="中文名称" :value="utils.isEffectiveCommon(supplierForm.orgName)" />
            <van-cell title="供应商编号" :value="utils.isEffectiveCommon(supplierForm.orgNo)" />
            <van-cell title="供应商类型" :value="utils.statusFormat(String(supplierForm.supplierType),'SupplierType')" />
            <!--            <van-cell title="厂号" :value="utils.isEffectiveCommon(supplierForm.factoryNumber)" />-->
            <van-cell title="成立时间" :value="utils.isEffectiveCommon(supplierForm.establishDate)" />
            <van-cell title="联系电话" :value="utils.isEffectiveCommon(supplierForm.orgContactPhone)" />
            <van-cell title="电子邮箱" :value="utils.isEffectiveCommon(supplierForm.enterpriseEmail)" />
            <van-cell title="地址" :value="utils.isEffectiveCommon(supplierForm.orgWorkDetail)" />
            <van-cell title="企业基本情况" :value="utils.isEffectiveCommon(supplierForm.enterpriseBscInformation)" />
          </van-cell-group>
        </div>
        <div class="area-title">
          <p class="title">
            收款信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group v-if="supplierForm.enterpriseCollectionInfoVoList && supplierForm.enterpriseCollectionInfoVoList.length>0">
            <div v-for="item in supplierForm.enterpriseCollectionInfoVoList" :key="item.id" class="account-item">
              <p><span>收款方名称</span><span>{{ item.payeeName }}</span></p>
              <p><span>银行账号</span><span>{{ item.bankAccount }}</span></p>
              <p><span>收款银行</span><span>{{ item.receivingBank }}</span></p>
              <p><span>SWIFT CODE</span><span>{{ item.swiftCode }}</span></p>
              <van-divider />
            </div>
          </van-cell-group>
          <van-empty v-else description="暂无数据" />
        </div>
        <div class="area-title">
          <p class="title">
            证照资料
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group v-if="supplierForm.fileDetailInfoVoList && supplierForm.fileDetailInfoVoList.length>0">
            <file-list :file-list="supplierForm.fileDetailInfoVoList" title="" />
          </van-cell-group>
          <van-empty v-else description="暂无数据" />
        </div>
      </van-tab>
      <van-tab title="产品模式">
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="产品类型" :value="utils.statusFormat(String(orderDetail.productType), 'ProjectProductType')" />
          </van-cell-group>
          <van-cell-group v-for="productMode in productModeList" :key="productMode.projectNo" :title="'支付节点：' + productMode.payNode">
            <van-cell title="支付比例（%）：" :value="utils.isEffectiveCommon(productMode.payScale)" />
            <van-cell title="支付要件：" value="" />
            <van-cell v-for="(item, index) in productMode.projectProductModeTempDocsVoList" :key="productMode.payNodeNo + index" :title="(index + 1) + '.' + utils.isEffectiveCommon(item.payDocsName)" />
            <van-cell title="备注：" :value="utils.isEffectiveCommon(productMode.remarks)" />
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="交易方案">
        <div class="area-title">
          <p class="title">
            交易信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="原币总金额" :value="utils.moneyFormat(goodsForm.totalMoney)" />
            <van-cell title="约定汇率" :value="utils.moneyFormat(goodsForm.agreedExchangeRate)" />
            <van-cell title="本币估值（元）" :value="utils.moneyFormat(goodsForm.valuation)" />
            <van-cell title="预付款比例（%）" :value="utils.moneyFormat(goodsForm.advanceChargeProportion)" />
            <van-cell title="原币预付款金额" :value="utils.moneyFormat(goodsForm.advancePaymentMoney)" />
          </van-cell-group>
          <div class="button-area">
            <van-button square type="info" native-type="button" @click="showGoodsPopup = true">
              查看货物明细
            </van-button>
          </div>
          <van-popup v-model="showGoodsPopup" round position="bottom" class="popup">
            <van-nav-bar
              title="货物明细"
            />
            <div class="list">
              <van-cell-group v-for="(item, index) in goodsForm.orderTransactionPurchaseInfoVoList" :key="item.id" class="goods-cell-group">
                <van-cell title="序号" :value="index + 1" />
                <van-cell title="合同号(PI/SC)" :value="utils.isEffectiveCommon(item.pi)" />
                <van-cell title="合同日期" :value="utils.dateFormat(item.contractDate)" />
                <van-cell title="货物名称" :value="utils.isEffectiveCommon(item.productName)" />
                <van-cell title="国家" :value="utils.statusFormat(item.country, 'Country')" />
                <van-cell title="原产地" :value="utils.isEffectiveCommon(item.countryOfOrigin)" />
                <van-cell title="HS编码" :value="utils.isEffectiveCommon(item.hsCode)" />
                <van-cell :title="'原币单价' + utils.statusFormat(goodsForm.currency,'OriginalCurrency') + '/KG'" :value="utils.moneyFormat(item.unitPrice)" />
                <van-cell title="重量（KG）" :value="utils.moneyFormat(item.weight)" />
                <van-cell title="原币金额" :value="utils.isEffectiveCommon(item.money)" />
                <file-list :file-list="item.fileDetailInfoVoList" title="采购合同" />
                <van-cell-group title="市场参考报价">
                  <van-cell title="期货单价">
                    <template #default>
                      <p>{{ utils.isEffectiveCommon(item.futuresGoodsPriceUsdKg) + 'USD/KG' }}</p>
                      <p>{{ utils.isEffectiveCommon(item.spotGoodsPriceRmbKg) + 'RMB/KG' }}</p>
                    </template>
                  </van-cell>
                  <van-cell title="现货单价" :value="utils.isEffectiveCommon(item.spotGoodsPriceRmbKg) + 'RMB/KG'" />
                </van-cell-group>
              </van-cell-group>
            </div>
            <div class="button-area">
              <van-button round block type="info" native-type="submit" @click="showGoodsPopup = false">
                关闭
              </van-button>
            </div>
          </van-popup>
        </div>
        <div class="area-title">
          <p class="title">
            垫资信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="定金比例（%）" :value="utils.isEffectiveCommon(fundsForm.marginLevel)" />
            <van-cell title="预期追加定金" :value="`每低于市场价格 ${utils.isEffectiveCommon(fundsForm.lessThrid)}% 时，追加定金比例${utils.isEffectiveCommon(fundsForm.marginCall)}`" />
            <van-cell title="结算周期（天）" :value="utils.isEffectiveCommon(fundsForm.settlementCycle)" />
            <van-cell title="计费方式" :value="utils.statusFormat(String(fundsForm.billingMethod),'ProjectBillingMethod')" />
            <van-cell title="最低起息天数" :value="utils.isEffectiveCommon(fundsForm.minValueDays)" />
            <van-cell title="逾期费率（%）" :value="utils.isEffectiveCommon(fundsForm.overdueRate)" />
            <van-cell title="资金服务费" value="阶梯费用" />
          </van-cell-group>
        </div>
        <div class="table">
          <div class="th">
            <div class="fl">
              公历年度累计柜数
            </div>
            <div class="fl">
              执行年费率
            </div>
            <div class="clearfix" />
          </div>
          <div v-for="(platformRate) in fundsForm.orderPurchaseSaleInfoVoList" :key="platformRate.id" class="tr">
            <div class="fl">
              {{ platformRate.range }}
            </div>
            <div class="fl">
              {{ utils.isEffectiveCommon(platformRate.rate) }}
            </div>
            <div class="clearfix" />
            <van-divider />
          </div>
          <van-empty v-if="!fundsForm.orderPurchaseSaleInfoVoList" description="暂无数据" />
        </div>
        <van-cell title="本订单所属项目中累计执行柜数" :value="utils.isEffectiveCommon(fundsForm.cabinetNos)" />
        <van-cell title="本订单预期执行年费率" :value="utils.isEffectiveCommon(fundsForm.expectedFeeRate)" />
        <van-cell title="收费标准" :value="utils.isEffectiveCommon(fundsForm.platformServiceTypeRate)" />
        <!-- <van-cell title="代理费用类型" :value="utils.statusFormat(fundsForm.platformServiceType,'ProjectPlatformServiceType')" /> -->
        <!-- <template v-if="Number(fundsForm.platformServiceType) === 2">
          <van-cell title="打包报关费(元/吨）" :value="utils.moneyFormat(fundsForm.packDeclareCharge)" />
          <van-cell title="最低打包吨数（吨）" :value="utils.moneyFormat(fundsForm.minPackTonnage)" />
          <van-cell title="供应链管理服务费(元/吨）" :value="utils.moneyFormat(fundsForm.managementServiceCharge)" />
        </template> -->
        <template v-if="Number(fundsForm.platformServiceType) === 3">
          <van-cell title="平台手续费" :value="utils.moneyFormat(fundsForm.platformServiceTypeRate)" />
        </template>
      </van-tab>
      <van-tab title="合同信息">
        <div class="contract-list">
          <van-list
            v-model="contractLoading"
            :finished="contractFinished"
            :finished-text="finishedText"
            @load="getContractList"
          >
            <div v-for="item in contractList" :key="item.sealId" class="contract-item">
              <div>
                <div class="fl">
                  <p><span>合同名称</span><span>{{ item.contractName }}</span></p>
                  <p><span>合同编号</span> <span>{{ item.contractNumber }}</span></p>
                </div>
                <div class="fl">
                  <van-button plain round size="small" type="info" native-type="button" @click="contractDetail(item)">
                    查看详情
                  </van-button>
                </div>
                <div class="clearfix" />
              </div>
              <van-divider />
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="相关附件">
        <attachment-list module-name="ORDER" :business-id="orderDetail.orderId" />
      </van-tab>
      <van-tab title="审核意见">
        <workflow-audit v-if="Number($route.query.taskViewMode)!==0" page-key="order_apply" :business-key="orderDetail.orderId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:orderDetail.orderId ,motif:orderDetail.orderName}" :view-mode="true" @confirmAudit="utils.backReturn($route.query.messageType)" />
        <workflow-audit v-else page-key="order_apply" :business-key="orderDetail.orderId" :task-id="$route.query.taskId" :variable="{businessId:orderDetail.orderId ,motif:orderDetail.orderName}" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tabs>
    <back-button />
  </div>
</template>

<script>
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, Tab, Tabs, Toast, Empty, Popup, NavBar } from 'vant'
import AttachmentList from '@/components/attachment-list'
import WorkflowAudit from '@/components/workflow-audit'
import BackButton from '@/components/back-button'
import fileList from '@/components/file-list'
export default {
  components: {
    fileList,
    BackButton,
    AttachmentList,
    WorkflowAudit,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [Empty.name]: Empty,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar
  },
  data () {
    return {
      id: this.$route.query.id,
      active: 0,
      orderDetail: {
      },
      productModeList: [],
      goodsForm: {},
      showGoodsPopup: false,

      fundsForm: {
        // 保证金（定金）比例
        marginLevel: '',
        lessThrid: '',
        marginCall: '',
        settlementCycle: '',
        billingMethod: '',
        minValueDays: '',
        funderRateList: [],
        platformRateList: [],
        projectPurchaseSaleInfoVoList: [],
        platformServiceTypeRate: '',
        expectedFeeRate: ''
      },
      contractLoading: false,
      contractFinished: false,
      contractList: [],
      finishedText: '没有更多了',
      supplierForm: {
        orderId: '',
        englishName: '',
        orgName: '',
        orgNo: '',
        orgRegCountry: '',
        orgType: '',
        factoryNumber: '',
        establishDate: '',
        orgContactPhone: '',
        enterpriseEmail: '',
        orgRegProvince: '',
        orgRegCity: '',
        orgRegXq: '',
        orgRegDetail: '',
        enterpriseBscInformation: '',
        enterpriseCollectionInfoVoList: []
      }
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      Toast.loading({ duration: 0, forbidClick: true })
      this.api.order.detailNongtou({ orderId: this.id }).then(result => {
        this.orderDetail = result.data.value
        this.supplierForm = Object.assign(this.supplierForm, this.orderDetail)
        this.getEnterpriseDetail(this.orderDetail.usEnterpriseId)
        this.getSupplier(this.orderDetail.orderId)
        this.getDealGoodsList()
        this.getProductModeDetail().then(result => {
          result.forEach(item => {
            item.projectProductModeTempDocsVoList = item.payDocsList.filter(o => o.checked === 1)
          })
          this.productModeList = result
        }).catch(() => {
          this.getProductModeTemplate().then(result => {
            this.productModeList = result
          })
        })
        this.getCategoryList().then(result => {
          if (result) {
            const data = this._.find(result, { cargoClassifyId: this.orderDetail.cargoClassifyParentId }) || {}
            this.orderDetail.firstCategoryName = data.classifyName
          }
        }).catch(() => {
        })
        this.getFundsDetail().then(result => {
          if (!this._.isEmpty(result)) {
            const fundsForm = result
            fundsForm.orderPurchaseSaleInfoVoList = this._.filter(result.orderPurchaseSaleInfoVoList, { serviceType: 0 }) || [
              { startDay: 1, endDay: undefined, serviceType: 0, rate: undefined },
              { startDay: null, endDay: undefined, serviceType: 0, rate: undefined }
            ]
            fundsForm.orderPurchaseSaleInfoVoList.forEach((item, index) => {
              if (index !== fundsForm.orderPurchaseSaleInfoVoList.length - 1) {
                fundsForm.orderPurchaseSaleInfoVoList[index].range = this.startDayFormat(fundsForm.orderPurchaseSaleInfoVoList, index) + '~' + item.endDay
              } else {
                fundsForm.orderPurchaseSaleInfoVoList[index].range = this.startDayFormat(fundsForm.orderPurchaseSaleInfoVoList, index) + '以上'
              }
            })
            this.fundsForm = fundsForm
            if (!this.fundsForm.cabinetNos) {
              this.fundsForm.cabinetNos = this.orderDetail.cabinetNos
            }
            // 计算本订单预期执行年费率
            this.expectedFeeRateChange()
          }
        }).catch(() => {
        })
        this.api.order.getCustomerDetailByOrderId({ orderId: this.id }).then(resultCustmor => {
          this.orderDetail = { ...this.orderDetail, ...resultCustmor.data.value }
        }).catch(() => {
        })
      }).finally(() => {
        Toast.clear()
      })
    },
    expectedFeeRateChange () {
      if (!this._.isEmpty(this.fundsForm.orderPurchaseSaleInfoVoList) && this.fundsForm.cabinetNos) {
        this.fundsForm.orderPurchaseSaleInfoVoList.forEach((item, index) => {
          if (index === this.fundsForm.orderPurchaseSaleInfoVoList.length - 1) {
            if (Number(item.startDay) <= Number(this.fundsForm.cabinetNos)) {
              this.fundsForm.expectedFeeRate = item.rate
            }
          } else {
            if (this._.inRange(Number(this.fundsForm.cabinetNos), Number(item.startDay), Number(item.endDay) + 1)) {
              this.fundsForm.expectedFeeRate = item.rate
            }
          }
        })
      }
    },
    getProductModeDetail () {
      return new Promise((resolve, reject) => {
        this.api.order.productModeDetail({ orderId: this.orderDetail.orderId }).then(result => {
          if (result.data.value) {
            resolve(result.data.value)
          } else {
            reject(result.data.value)
          }
        }).catch(e => {
          reject(e)
        }).finally(() => {
        })
      })
    },
    getProductModeTemplate () {
      return new Promise((resolve, reject) => {
        this.api.project.productModeTemple().then(res => {
          const list = this._.filter(res.data.value, { productMode: this.orderDetail.productType })
          resolve(list)
        }).catch(e => {
          reject(e)
        }).finally(() => {
        })
      })
    },
    // 获取分类
    getCategoryList () {
      return new Promise((resolve, reject) => {
        this.api.cargoManagement.categoryList({ cargoParentId: 0 }).then(result => {
          resolve(result.data.value)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getDealGoodsList () {
      this.api.order.dealPlanNongtou({ orderId: this.orderDetail.orderId }).then(result => {
        this.goodsForm = result.data.value || {}
      }).finally(() => {
        Toast.clear()
      })
    },
    // 获取垫资信息
    getFundsDetail () {
      return new Promise((resolve, reject) => {
        this.api.order.fundsInfo({ orderId: this.orderDetail.orderId }).then(result => {
          resolve(result.data.value)
        }).catch(e => {
          reject(e)
        })
      })
    },
    // 开始日期格式化 会改动数据
    startDayFormat (list, index) {
      const beforeData = list[index - 1]
      if (beforeData) {
        list[index].startDay = beforeData.endDay ? beforeData.endDay + 1 : undefined
      } else {
        list[index].startDay = 1
      }
      return this.utils.isEffectiveCommon(list[index].startDay)
    },
    getContractList () {
      this.api.contract.listPage({ pageSize: 100, orderId: this.orderDetail.orderId, projectId: this.orderDetail.projectId }).then(res => {
        this.contractList = res.data.value.list || []
        this.contractFinished = true
        if (this.contractList.length === 0) {
          this.finishedText = '暂无数据'
        }
      }).finally(() => {
      })
    },
    contractDetail (row) {
      this.$router.push({ name: 'contractDetail', query: { id: row.sealId, procInstId: row.processId, taskViewMode: 1 } })
    },
    getEnterpriseDetail (value) {
      this.api.order.getEnterpriseDetail(value).then(result => {
        const data = Object.assign(this.supplierForm, result.data.value)
        delete data.enterpriseCollectionInfoVoList
        delete data.fileDetailInfoVoList
        delete data.enterpriseBscInformation
        this.supplierForm = Object.assign(this.supplierForm, data)
      }).finally(() => {
      })
    },
    getSupplier (value) {
      this.api.order.getSupplierNongtou({ orderId: value }).then(result => {
        const data = result.data.value
        if (this._.isEmpty(data.enterpriseCollectionInfoVoList)) {
          data.enterpriseCollectionInfoVoList = []
        }
        if (this._.isEmpty(data.fileDetailInfoVoList)) {
          data.fileDetailInfoVoList = []
        }
        this.supplierForm = Object.assign(this.supplierForm, data)
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.order-detail {
  .van-tabs {
      margin-bottom: 60px;
  }
  .button-area {
    margin: 5px 10px;
    >button {
      width: 100%;
    }
  }
  .van-nav-bar{
    position: static;
  }
  .popup {
    height: 80%;
    .van-nav-bar{
      position: absolute;
      top: 0;
    }
    .list {
      height: 80%;
      margin-top: 40px;
      overflow-y: scroll;
    }
    .button-area {
      position: absolute;
      bottom: 0px;
      height: 55px;
      width: calc(100% - 20px);
      left: 50%;
      margin-left: calc(-50% + 10px);
      background-color: #FFF;
      z-index: 11;
    }
    .goods-cell-group {
      border:1px solid #ccc;
      margin: 10px 15px;
    }
  }
  .contract-list {
    .contract-item {
      font-size: 14px;
      margin-top: 20px;
      >div>div:first-child {
        width: 70%;
        p {
          margin-left: 20px;
          >span {
            display: inline-block;
            height: 16px;
            line-height: 16px;
            vertical-align: middle;
          }
          >span:nth-child(2) {
            margin-left: 15px;
            overflow: hidden;
            width: 150px;
          }
        }
      }
      >div>div:nth-child(2) {
        width: 30%;
        text-align: center;
      }
      .van-divider {
        margin-top: 5px;
      }

    }
  }
  .account-item {
    font-size: 14px;
    margin-top: 10px;
    p {
      margin-left: 20px;
      >span {
        display: inline-block;
        height: 16px;
        line-height: 16px;
        vertical-align: middle;
      }
      >span:first-child {
        width: 100px;
        text-align: right;
      }
      >span:nth-child(2) {
        margin-left: 15px;
        overflow: hidden;
      }
    }
    .van-divider {
      margin-top: 5px;
    }

  }
  .table {
    font-size: 14px;
    margin: auto 10px;
    padding-bottom: 10px;
    .th {
      background-color: rgb(242, 242, 242);
      border: 1px solid rgb(215, 215, 215);
      border-radius: 5px 5px 0px 0px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-weight: 700;
      >div:first-child {
        width: 50%;
      }
      >div:nth-child(2) {
        width: 50%;
      }
    }
    .tr {
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-weight: 400;
      >div:first-child {
        width: 50%;
      }
      >div:nth-child(2) {
        width: 50%;
      }
    }
    .van-divider {
      margin-top: 0px;
    }
  }
}
</style>
