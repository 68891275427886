var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main order-detail" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "订单信息" } }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "项目名称",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.projectName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "项目编号",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.projectNo
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "产品类型",
                        value: _vm.utils.statusFormat(
                          String(_vm.orderDetail.productType),
                          "ProjectProductType"
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "货品分类",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.firstCategoryName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "订单名称",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.orderName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "订单编号",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.orderNo
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "统一社会信用代码",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.dsEnterpriseOrgNo
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "供应商编号",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.usEnterpriseOrgNo
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "委托客户",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.dsEnterpriseName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "供应商",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.usEnterpriseName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "贸易执行企业",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.tradeOrgName
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "资金方",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.tenantName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "项目创建人",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.createUserName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "关联主合同",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.contractName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "关联主合同编号",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.contractNumber
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "area-title" }, [
              _c("p", { staticClass: "title" }, [_vm._v(" 物流信息 ")])
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "物流方式",
                        value: _vm.utils.statusFormat(
                          Number(_vm.orderDetail.logisticsMode),
                          "LogisticsMode"
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "起运地",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.originPlace
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "装运口岸",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.shipmentPort
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "目的地",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.destination
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "目的口岸",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.destinationPort
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "申报口岸",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.entryPort
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "area-title" }, [
              _c("p", { staticClass: "title" }, [_vm._v(" 结算信息 ")])
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "结算方式",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.statementConfirmForm
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "付款条款",
                        value: _vm.utils.statusFormat(
                          _vm.orderDetail.paymentProvision,
                          "PaymentProvision"
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "area-title" }, [
              _c("p", { staticClass: "title" }, [_vm._v(" 交(提)货信息 ")])
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "结算周期（天）",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.fundsForm.settlementCycle
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "货物质量",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.billConfrimForm
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("van-tab", { attrs: { title: "客户信息" } }, [
            _c("div", { staticClass: "area-title" }, [
              _c("p", { staticClass: "title" }, [_vm._v(" 客户存量业务情况 ")])
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "柜数合计",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.cabinetsNumber
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "合同数量合计（吨）",
                        value: _vm.utils.weightFormat(
                          _vm.orderDetail.totalContractQuantity
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "已支付总金额（万美元）",
                        value: _vm.utils.moneyFormat(
                          _vm.orderDetail.paidtTotalContractAmount
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "销售回款（万元）",
                        value: _vm.utils.moneyFormat(
                          _vm.orderDetail.salesCollection
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "剩余可用额度(万元)",
                        value: _vm.utils.moneyFormat(
                          _vm.orderDetail.availableLimit
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "合同总金额（万美元）",
                        value: _vm.utils.moneyFormat(
                          _vm.orderDetail.totalContractAmountUs
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "合同总金额（万元）",
                        value: _vm.utils.moneyFormat(
                          _vm.orderDetail.totalContractAmount
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "实际占用资金（万元）",
                        value: _vm.utils.moneyFormat(
                          _vm.orderDetail.actualOccupiedFunds
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "关联主合同",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.orderDetail.contractNumber
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("van-tab", { attrs: { title: "供应商信息" } }, [
            _c("div", { staticClass: "area-title" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(" 海外供应商基本信息 ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "海外供应商英文名称",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.supplierForm.englishName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "中文名称",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.supplierForm.orgName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "供应商编号",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.supplierForm.orgNo
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "供应商类型",
                        value: _vm.utils.statusFormat(
                          String(_vm.supplierForm.supplierType),
                          "SupplierType"
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "成立时间",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.supplierForm.establishDate
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "联系电话",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.supplierForm.orgContactPhone
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "电子邮箱",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.supplierForm.enterpriseEmail
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "地址",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.supplierForm.orgWorkDetail
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "企业基本情况",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.supplierForm.enterpriseBscInformation
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "area-title" }, [
              _c("p", { staticClass: "title" }, [_vm._v(" 收款信息 ")])
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _vm.supplierForm.enterpriseCollectionInfoVoList &&
                _vm.supplierForm.enterpriseCollectionInfoVoList.length > 0
                  ? _c(
                      "van-cell-group",
                      _vm._l(
                        _vm.supplierForm.enterpriseCollectionInfoVoList,
                        function(item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "account-item" },
                            [
                              _c("p", [
                                _c("span", [_vm._v("收款方名称")]),
                                _c("span", [_vm._v(_vm._s(item.payeeName))])
                              ]),
                              _c("p", [
                                _c("span", [_vm._v("银行账号")]),
                                _c("span", [_vm._v(_vm._s(item.bankAccount))])
                              ]),
                              _c("p", [
                                _c("span", [_vm._v("收款银行")]),
                                _c("span", [_vm._v(_vm._s(item.receivingBank))])
                              ]),
                              _c("p", [
                                _c("span", [_vm._v("SWIFT CODE")]),
                                _c("span", [_vm._v(_vm._s(item.swiftCode))])
                              ]),
                              _c("van-divider")
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  : _c("van-empty", { attrs: { description: "暂无数据" } })
              ],
              1
            ),
            _c("div", { staticClass: "area-title" }, [
              _c("p", { staticClass: "title" }, [_vm._v(" 证照资料 ")])
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _vm.supplierForm.fileDetailInfoVoList &&
                _vm.supplierForm.fileDetailInfoVoList.length > 0
                  ? _c(
                      "van-cell-group",
                      [
                        _c("file-list", {
                          attrs: {
                            "file-list": _vm.supplierForm.fileDetailInfoVoList,
                            title: ""
                          }
                        })
                      ],
                      1
                    )
                  : _c("van-empty", { attrs: { description: "暂无数据" } })
              ],
              1
            )
          ]),
          _c("van-tab", { attrs: { title: "产品模式" } }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "产品类型",
                        value: _vm.utils.statusFormat(
                          String(_vm.orderDetail.productType),
                          "ProjectProductType"
                        )
                      }
                    })
                  ],
                  1
                ),
                _vm._l(_vm.productModeList, function(productMode) {
                  return _c(
                    "van-cell-group",
                    {
                      key: productMode.projectNo,
                      attrs: { title: "支付节点：" + productMode.payNode }
                    },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "支付比例（%）：",
                          value: _vm.utils.isEffectiveCommon(
                            productMode.payScale
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: { title: "支付要件：", value: "" }
                      }),
                      _vm._l(
                        productMode.projectProductModeTempDocsVoList,
                        function(item, index) {
                          return _c("van-cell", {
                            key: productMode.payNodeNo + index,
                            attrs: {
                              title:
                                index +
                                1 +
                                "." +
                                _vm.utils.isEffectiveCommon(item.payDocsName)
                            }
                          })
                        }
                      ),
                      _c("van-cell", {
                        attrs: {
                          title: "备注：",
                          value: _vm.utils.isEffectiveCommon(
                            productMode.remarks
                          )
                        }
                      })
                    ],
                    2
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "交易方案" } },
            [
              _c("div", { staticClass: "area-title" }, [
                _c("p", { staticClass: "title" }, [_vm._v(" 交易信息 ")])
              ]),
              _c(
                "div",
                { staticClass: "van-common-detail" },
                [
                  _c(
                    "van-cell-group",
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "原币总金额",
                          value: _vm.utils.moneyFormat(_vm.goodsForm.totalMoney)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "约定汇率",
                          value: _vm.utils.moneyFormat(
                            _vm.goodsForm.agreedExchangeRate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "本币估值（元）",
                          value: _vm.utils.moneyFormat(_vm.goodsForm.valuation)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "预付款比例（%）",
                          value: _vm.utils.moneyFormat(
                            _vm.goodsForm.advanceChargeProportion
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "原币预付款金额",
                          value: _vm.utils.moneyFormat(
                            _vm.goodsForm.advancePaymentMoney
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "button-area" },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: {
                            square: "",
                            type: "info",
                            "native-type": "button"
                          },
                          on: {
                            click: function($event) {
                              _vm.showGoodsPopup = true
                            }
                          }
                        },
                        [_vm._v(" 查看货物明细 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-popup",
                    {
                      staticClass: "popup",
                      attrs: { round: "", position: "bottom" },
                      model: {
                        value: _vm.showGoodsPopup,
                        callback: function($$v) {
                          _vm.showGoodsPopup = $$v
                        },
                        expression: "showGoodsPopup"
                      }
                    },
                    [
                      _c("van-nav-bar", { attrs: { title: "货物明细" } }),
                      _c(
                        "div",
                        { staticClass: "list" },
                        _vm._l(
                          _vm.goodsForm.orderTransactionPurchaseInfoVoList,
                          function(item, index) {
                            return _c(
                              "van-cell-group",
                              { key: item.id, staticClass: "goods-cell-group" },
                              [
                                _c("van-cell", {
                                  attrs: { title: "序号", value: index + 1 }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "合同号(PI/SC)",
                                    value: _vm.utils.isEffectiveCommon(item.pi)
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "合同日期",
                                    value: _vm.utils.dateFormat(
                                      item.contractDate
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "货物名称",
                                    value: _vm.utils.isEffectiveCommon(
                                      item.productName
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "国家",
                                    value: _vm.utils.statusFormat(
                                      item.country,
                                      "Country"
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "原产地",
                                    value: _vm.utils.isEffectiveCommon(
                                      item.countryOfOrigin
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "HS编码",
                                    value: _vm.utils.isEffectiveCommon(
                                      item.hsCode
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title:
                                      "原币单价" +
                                      _vm.utils.statusFormat(
                                        _vm.goodsForm.currency,
                                        "OriginalCurrency"
                                      ) +
                                      "/KG",
                                    value: _vm.utils.moneyFormat(item.unitPrice)
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "重量（KG）",
                                    value: _vm.utils.moneyFormat(item.weight)
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "原币金额",
                                    value: _vm.utils.isEffectiveCommon(
                                      item.money
                                    )
                                  }
                                }),
                                _c("file-list", {
                                  attrs: {
                                    "file-list": item.fileDetailInfoVoList,
                                    title: "采购合同"
                                  }
                                }),
                                _c(
                                  "van-cell-group",
                                  { attrs: { title: "市场参考报价" } },
                                  [
                                    _c("van-cell", {
                                      attrs: { title: "期货单价" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function() {
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.utils.isEffectiveCommon(
                                                        item.futuresGoodsPriceUsdKg
                                                      ) + "USD/KG"
                                                    )
                                                  )
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.utils.isEffectiveCommon(
                                                        item.spotGoodsPriceRmbKg
                                                      ) + "RMB/KG"
                                                    )
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "现货单价",
                                        value:
                                          _vm.utils.isEffectiveCommon(
                                            item.spotGoodsPriceRmbKg
                                          ) + "RMB/KG"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "button-area" },
                        [
                          _c(
                            "van-button",
                            {
                              attrs: {
                                round: "",
                                block: "",
                                type: "info",
                                "native-type": "submit"
                              },
                              on: {
                                click: function($event) {
                                  _vm.showGoodsPopup = false
                                }
                              }
                            },
                            [_vm._v(" 关闭 ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "area-title" }, [
                _c("p", { staticClass: "title" }, [_vm._v(" 垫资信息 ")])
              ]),
              _c(
                "div",
                { staticClass: "van-common-detail" },
                [
                  _c(
                    "van-cell-group",
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "定金比例（%）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.fundsForm.marginLevel
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "预期追加定金",
                          value:
                            "每低于市场价格 " +
                            _vm.utils.isEffectiveCommon(
                              _vm.fundsForm.lessThrid
                            ) +
                            "% 时，追加定金比例" +
                            _vm.utils.isEffectiveCommon(
                              _vm.fundsForm.marginCall
                            )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "结算周期（天）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.fundsForm.settlementCycle
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "计费方式",
                          value: _vm.utils.statusFormat(
                            String(_vm.fundsForm.billingMethod),
                            "ProjectBillingMethod"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "最低起息天数",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.fundsForm.minValueDays
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "逾期费率（%）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.fundsForm.overdueRate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: { title: "资金服务费", value: "阶梯费用" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table" },
                [
                  _c("div", { staticClass: "th" }, [
                    _c("div", { staticClass: "fl" }, [
                      _vm._v(" 公历年度累计柜数 ")
                    ]),
                    _c("div", { staticClass: "fl" }, [_vm._v(" 执行年费率 ")]),
                    _c("div", { staticClass: "clearfix" })
                  ]),
                  _vm._l(_vm.fundsForm.orderPurchaseSaleInfoVoList, function(
                    platformRate
                  ) {
                    return _c(
                      "div",
                      { key: platformRate.id, staticClass: "tr" },
                      [
                        _c("div", { staticClass: "fl" }, [
                          _vm._v(" " + _vm._s(platformRate.range) + " ")
                        ]),
                        _c("div", { staticClass: "fl" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.utils.isEffectiveCommon(platformRate.rate)
                              ) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "clearfix" }),
                        _c("van-divider")
                      ],
                      1
                    )
                  }),
                  !_vm.fundsForm.orderPurchaseSaleInfoVoList
                    ? _c("van-empty", { attrs: { description: "暂无数据" } })
                    : _vm._e()
                ],
                2
              ),
              _c("van-cell", {
                attrs: {
                  title: "本订单所属项目中累计执行柜数",
                  value: _vm.utils.isEffectiveCommon(_vm.fundsForm.cabinetNos)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "本订单预期执行年费率",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.fundsForm.expectedFeeRate
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "收费标准",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.fundsForm.platformServiceTypeRate
                  )
                }
              }),
              Number(_vm.fundsForm.platformServiceType) === 3
                ? [
                    _c("van-cell", {
                      attrs: {
                        title: "平台手续费",
                        value: _vm.utils.moneyFormat(
                          _vm.fundsForm.platformServiceTypeRate
                        )
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("van-tab", { attrs: { title: "合同信息" } }, [
            _c(
              "div",
              { staticClass: "contract-list" },
              [
                _c(
                  "van-list",
                  {
                    attrs: {
                      finished: _vm.contractFinished,
                      "finished-text": _vm.finishedText
                    },
                    on: { load: _vm.getContractList },
                    model: {
                      value: _vm.contractLoading,
                      callback: function($$v) {
                        _vm.contractLoading = $$v
                      },
                      expression: "contractLoading"
                    }
                  },
                  _vm._l(_vm.contractList, function(item) {
                    return _c(
                      "div",
                      { key: item.sealId, staticClass: "contract-item" },
                      [
                        _c("div", [
                          _c("div", { staticClass: "fl" }, [
                            _c("p", [
                              _c("span", [_vm._v("合同名称")]),
                              _c("span", [_vm._v(_vm._s(item.contractName))])
                            ]),
                            _c("p", [
                              _c("span", [_vm._v("合同编号")]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.contractNumber))])
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "fl" },
                            [
                              _c(
                                "van-button",
                                {
                                  attrs: {
                                    plain: "",
                                    round: "",
                                    size: "small",
                                    type: "info",
                                    "native-type": "button"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.contractDetail(item)
                                    }
                                  }
                                },
                                [_vm._v(" 查看详情 ")]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "clearfix" })
                        ]),
                        _c("van-divider")
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "相关附件" } },
            [
              _c("attachment-list", {
                attrs: {
                  "module-name": "ORDER",
                  "business-id": _vm.orderDetail.orderId
                }
              })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "审核意见" } },
            [
              Number(_vm.$route.query.taskViewMode) !== 0
                ? _c("workflow-audit", {
                    attrs: {
                      "page-key": "order_apply",
                      "business-key": _vm.orderDetail.orderId,
                      "proc-inst-id": _vm.$route.query.procInstId,
                      variable: {
                        businessId: _vm.orderDetail.orderId,
                        motif: _vm.orderDetail.orderName
                      },
                      "view-mode": true
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
                : _c("workflow-audit", {
                    attrs: {
                      "page-key": "order_apply",
                      "business-key": _vm.orderDetail.orderId,
                      "task-id": _vm.$route.query.taskId,
                      variable: {
                        businessId: _vm.orderDetail.orderId,
                        motif: _vm.orderDetail.orderName
                      }
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }